<template>
  <div class="btn">
    <span @click='printPaper(0)'>导出试卷</span>
    <span @click='printPaper(1)'>导出试卷(附解析) </span>
  </div>
</template>

<script setup>
import { exportPaper } from '@/api/exportPaper.js'
import { toRefs } from "@vue/reactivity";
const propsP = defineProps({
  id: {
    type: Number,
    default: null
  }
})

const { id } = toRefs(propsP)

// 打印试卷
const printPaper = async (detail) => {
  const data = await exportPaper({
    user_paper_id: id.value,
    detail
  })
  let i = ''
  if (detail == 0) {
    i = "无解析"
  } else {
    i = '附解析'
  }
  const a = document.createElement("a")
  a.href = window.URL.createObjectURL(data.data)
  a.download = `${id.value}_${i}.pdf`
  a.click()
}
</script>

<style lang='scss' scoped>
.btn {
  background: #f6f6f6;
  margin-bottom: 20rem;
  span {
    font-size: 20rem;
    margin-left: 30rem;
    padding: 10rem 20rem;
    border: 1px solid #2196f3;
    border-radius: 25rem;
    color: #2196f3;
    cursor: pointer;
  }
}
</style>