<template>
  <div v-drag>
    <a-modal title="答题说明"
             v-model:visible="dialogVisible"
             :maskClosable='false'
             width="600px"
             @cancel="back"
             cancelText="取消"
             okText="开始答题"
             @ok="handleClose">
      <div class="text_warp">
        <p> 1.选择题请点击选项方框。</p>
        <p>2.填空题和解答题请在自备纸张上填写题号和相应答案，完成试卷后进入答题卡页面，拍摄答案照片(最多只能上传6张照片，每张照片不超过10M)上传后点击提交答案。</p>
        <p>3.点击提交可以提前交卷，交卷后可在我的试卷中查看。</p>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router"

const { dialogVisible } = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['expalinSubmitDapaper'])
const router = useRouter()
const handleClose = () => {
  emit("expalinSubmitDapaper")
}

const back = () => {
  router.go(-1)
}

</script>

<style lang="scss" scoped>
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 23px;
  font-size: 16px;
  padding: 27px;
  padding-top: 0;
}
.btn_warp {
  height: 50px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(1) {
      font-size: 18px;
      font-weight: bold;
      color: #999999;
      border-right: 1px solid #e5e5e5;
    }
    &:nth-child(2) {
      font-size: 18px;
      font-weight: bold;
      color: #2196f3;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  display: none;
}
::v-deep .el-dialog__header {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
::v-deep .el-dialog__title {
  color: #333333;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}
::v-deep .el-dialog {
  border-radius: 10px;
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>