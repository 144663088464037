<template>
  <div>
    <!-- ‘1’=>’单选’,2=>多选，3=>不定项选，4=>双项选。
	  5=>填空，6=>多子题，7=>非选择题，8=>判断正，
	  9=>ABCDEFG ，13六选五 14 15-->
    <div v-if="checkArr.indexOf(type) != -1 ">
      <!-- 七选五的时候才会显示这个 -->
      <div v-if="type == '9'"
           class="item_warp">
        <div :class="{'item':true,'pitchOn':answer.indexOf(item) != -1}"
             @click="add(item)"
             v-for="item in moreSelect"
             :key="item">
          {{item}}
        </div>
      </div>
      <!-- 六选五的时候才会显示这个 -->
      <div v-if="type == '13'"
           class="item_warp">
        <div :class="{'item':true,'pitchOn':answer.indexOf(item) != -1}"
             @click="add(item)"
             v-for="item in sexSelect"
             :key="item">
          {{item}}
        </div>
      </div>
      <!-- 五选五的时候才会显示这个 -->
      <div v-if="type == '14'"
           class="item_warp">
        <div :class="{'item':true,'pitchOn':answer.indexOf(item) != -1}"
             @click="add(item)"
             v-for="item in fiveSelects"
             :key="item">
          {{item}}
        </div>
      </div>
      <!-- 海南三个选项的选择题 -->
      <div v-if="type == '15'"
           class="item_warp">
        <div :class="{'item':true,'pitchOn':answer.indexOf(item) != -1}"
             @click="add(item)"
             v-for="item in threeSelect"
             :key="item">
          {{item}}
        </div>
      </div>
      <div v-if="type == '12'"
           class="item_warp">
        <div :class="{'item':true,'pitchOn':answer.indexOf(item) != -1}"
             @click="add(item)"
             v-for="item in threeSelect"
             :key="item">
          {{item}}
        </div>
      </div>
      <!-- 单选多选都显示这个，只有四个选项 -->
      <div v-if="type == '1' || type == '2' || type == '4' || type == '3'"
           class="item_warp">
        <div :class="{'item':true,'pitchOn':answer.indexOf(item) != -1}"
             @click="add(item)"
             v-for="item in items"
             :key="item">
          {{item}}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { checkArr as globalCheckArr } from '@/init/index.js'

const { type, answer, index } = defineProps({
  type: {
    type: String,
    default: ''
  },
  answer: {
    type: Array,
    default: []
  },
  index: {
    type: Number,
    default: 0
  }
})
const emit = defineEmits(['setAnswer'])

let items = ref(['A', 'B', 'C', 'D'])
let fiveSelects = ref(['A', 'B', 'C', 'D', 'E'])
let moreSelect = ref(['A', 'B', 'C', 'D', 'E', 'F', 'G'])
let sexSelect = ref(['A', 'B', 'C', 'D', 'E', 'F'])
let threeSelect = ref(['A', 'B', 'C'])
let checkArr = globalCheckArr

const add = (item) => {
  emit('setAnswer', item, index)
}
</script>

<style lang="scss" scoped>
.item {
  flex: 1;
  height: 70rem;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  font-size: 28rem;
  border: 1px solid #eeeeee;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pitchOn {
  color: white;
  background-color: #2196f3;
  border: 1px solid #2196f3;
}
.item_warp {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  // justify-content: center;
}
</style>