<template>
  <div v-drag>
    <a-modal title="提示"
             v-model:visible="dialogVisible"
             width="400px"
             :bodyStyle="bodyStyle"
             :maskClosable="false"
             @cancel="handleClose"
             :footer="null">
      <div class="text_warp"
           v-if="num == 0">
        您还有<span style="color:#FF6147">{{num}}</span>道题未作答，是否确认提交
      </div>
      <div class="text_warp"
           v-else>
        您还有<span style="color:#FF6147">{{num}}</span>道题未作答，建议继续答题
      </div>
      <div class="btn_warp"
           v-if="num">
        <div @click="save">
          确认提交
        </div>
        <div @click="handleClose">
          继续做题
        </div>
      </div>
      <div class="btn_warp"
           v-else>
        <div @click="handleClose">
          取消
        </div>
        <div @click="save">
          确认提交
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, toRefs } from "@vue/reactivity"


let dialogVisible = ref(false)
let num = ref(0)
const setData = (number, bool) => {
  dialogVisible.value = bool
  num.value = number
}

const bodyStyle = {
  padding: "44rem 0 0 0 ",
  height: '200px'
}
const emit = defineEmits(['sava'])

const handleClose = () => {
  dialogVisible.value = false
}
const save = () => {
  emit('sava')
  handleClose()
}

defineExpose({
  setData
})
</script>

<style lang="scss" scoped>
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 23px;
  font-size: 16px;
  padding: 27px;
  padding-top: 15px;

  text-align: center;
  div {
    display: flex;
    justify-content: center;
  }
}
.btn_warp {
  height: 51px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:nth-child(1) {
      font-size: 18px;
      font-weight: bold;
      color: #999999;
      border-right: 1px solid #e5e5e5;
    }
    &:nth-child(2) {
      font-size: 18px;
      font-weight: bold;
      color: #2196f3;
    }
  }
}
</style>