<template>
  <div v-drag>
    <a-modal v-model:visible="dialogVisible"
             :bodyStyle="bodyStyle"
             :maskClosable="false"
             :closable="false"
             :footer="null"
             width="1050rem">
      <template #title>
        <div class="title">
          题目反馈
        </div>
      </template>

      <a-form :labelCol="{span:3}"
              :wrapperCol="{span:20}">
        <a-form-item label="题目ID:">
          <a-input placeholder="请输入题目ID"
                   type="textarea"
                   style="width:100%;"
                   v-model:value="form.question_id" />
        </a-form-item>
        <a-form-item label="内容:">
          <a-textarea placeholder="请输入反馈内容"
                      type="textarea"
                      style="width:100%;height:340rem"
                      v-model:value="form.comment" />
        </a-form-item>
        <a-row>
          <a-form-item label='注'
                       style="margin-bottom:10px;width:100%"
                       class="desction">

            <ol>
              <li>1.请仔细核对题目ID</li>
              <li>2.题目有错误，反馈内容才有效</li>

            </ol>
          </a-form-item>
        </a-row>
      </a-form>
      <div class="btn_warp">
        <div @click="handleClose">
          取消
        </div>
        <div @click="save">
          提交
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { reactive, ref } from "@vue/reactivity";
import { message } from "ant-design-vue";
import { topicPress } from '@/api/error.js'

let dialogVisible = ref(false)
let form = reactive({
  comment: '',
  question_id: ""
})

const bodyStyle = {
  padding: "0 ",
}

const save = async () => {
  if (form.question_id == '') {
    message.warning('请输入题目id')
    return
  }
  if (form.comment == '') {
    message.warning('请输入反馈内容')
    return
  }

  let cForm = JSON.parse(JSON.stringify(form))
  // cForm.question_id = form.question_id.slice(4, form.question_id.length)
  cForm.question_id = form.question_id
  const { status } = await topicPress(cForm)
  if (status == 200) {
    message.success('反馈成功')
  }
  dialogVisible.value = false
  form.comment = ''
  form.question_id = ''
}


const handleClose = () => {
  dialogVisible.value = false
}


defineExpose({
  dialogVisible,

})
</script>

<style lang="scss" scoped>
.desction {
  font-size: 24rem;
  font-weight: bold;
  color: #ff6147;
}

.btn_warp {
  height: 70rem;
  border-top: 1px solid #e5e5e5;
  display: flex;
  // position: absolute;
  bottom: 0;
  width: 100%;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:nth-child(1) {
      font-size: 24rem;
      font-weight: bold;
      color: #999999;
      border-right: 1px solid #e5e5e5;
    }
    &:nth-child(2) {
      font-size: 24rem;
      font-weight: bold;
      color: #2196f3;
    }
  }
}
.ant-form {
  padding: 0 20rem;
}
:deep .ant-form-item-label > label {
  font-size: 24rem;
  font-weight: 500;
  color: #333333;
}

:deep .ant-input {
  font-size: 24rem;
  font-weight: 500;
  color: #999999;
}
.title {
  font-size: 24rem !important;
}
</style>