<template>
  <div>
    <a-modal title="提示"
             v-model:visible="dialogVisible"
             @ok="handleClose"
             okText="继续做题"
             cancelText="退出"
             @cancel="handleClose"
             width="655rem">
      <div class="text_warp">
        <div>还未提交试卷，确定退出该试卷吗？</div>
        <div>(如果退出，该试卷可在未完成试卷里查询，可继续做题)</div>
      </div>
      <!-- <div class="btn_warp">
        <div @click="back">
          退出
        </div>
        <div @click="handleClose">
          继续做题
        </div>
      </div> -->
    </a-modal>
  </div>
</template>

<script setup>
const { dialogVisible } = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['save'])
const back = () => {
  emit('save')
  handleClose()
}
const handleClose = () => {
  dialogVisible.value = false
}
defineExpose({
  dialogVisible
})
</script>

<style lang="scss" scoped>
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 23px;
  font-size: 16px;
  padding: 27px;
  padding-top: 0;
  div {
    display: flex;
    justify-content: center;
  }
}
.btn_warp {
  height: 50px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(1) {
      font-size: 18px;
      font-weight: bold;
      color: #999999;
      border-right: 1px solid #e5e5e5;
    }
    &:nth-child(2) {
      font-size: 18px;
      font-weight: bold;
      color: #2196f3;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  display: none;
}
::v-deep .el-dialog__header {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
::v-deep .el-dialog {
  border-radius: 10px;
  margin-top: 30vh !important;
}
</style>