<template>
  <div>
    <!-- 大题的情况下 -->
    <div class="warp"
         v-if="topic.question_children">
      <!-- 左侧题目内容 -->
      <div class="left_topic">
        <div class="title">
          <span style="display:inline-block;max-width:calc(100% - 110rem)">
            题目{{topic.answer_id}}(<span v-if="topic.question_type_name">{{topic.question_type_name}}:</span>{{Number(topic.question_score)!=0? topic.question_score+'分':''}}
            {{score}}分，{{topic.source_name}})</span>
          <div class="question_id">
            <!-- ID:{{rands(topic.question_id)}} -->
            ID: {{topic.question_id}}
          </div>
        </div>
        <div v-html="topic.question_content"
             class="content">
        </div>
      </div>
      <!-- 右侧答题区域 -->
      <div class="right_topic_info">
        <div class="card"
             v-for="(item,index) in topic.question_children"
             :key="index">
          <div class="title">
            <span :style="{'color':item.has_knowledge?'red':''}">
              题目<span class="yuan">{{item.answer_id}}</span>
            </span>
            <span v-show="item.question_type_id == 0" :style="{'color':item.has_knowledge?'red':''}">
              该题目需纸上作答。
            </span>
            <div class="question_id">
              <!-- ID:{{rands(item.question_id)}} -->
              ID:{{item.question_id}}
            </div>
          </div>
          <div class="other_info">({{item.question_type_name}}: {{item.question_score}}分，{{item.source_name}})</div>
          <div v-html="item.question_content"
               class="content"></div>
          <Selects ref="SelectsRef"
                   @setAnswer="setAnswer"
                   :type="item.topic"
                   :index="index"
                   :answer="item.answer" />
        </div>
      </div>
    </div>

    <div class="xiao_warp"
         v-else>
      <div class="box">
        <div class="title">
          题目 <span class="yuan">{{topic.answer_id}}</span> ({{topic.question_type_name}}:{{topic.question_score}}分，{{topic.source_name}})<span class="yuan" v-show="topic.question_type_id == 0"> 该题目需纸上作答。</span>
          <div class="question_id">
            <!-- ID:{{rands(topic.question_id)}} -->
            ID:{{topic.question_id}}
          </div>
        </div>
        <div class="content"
             v-html="topic.question_content">
        </div>
        <div class="selectswarp">
          <Selects ref="SelectsRef"
                   @setAnswer="setAnswer"
                   :type="topic.topic"
                   :answer="topic.answer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Selects from './selects.vue'
import { nextTick } from 'vue'
import { rands as utilsRand } from '@/utils/rand.js'
import { computed } from '@vue/runtime-core'

const emit = defineEmits(['setAnswer'])
const { topic, topicIndex } = defineProps({
  topic: {
    type: Object,
    default: () => {
      return {}
    }
  },
  topicIndex: {
    type: Number,
    default: 0
  }
})

let score = computed(() => {
  let score = 0
  if (topic.question_children) {
    topic.question_children.forEach(item => {
      score += Number(item.question_score)
    })
    return score
  }
})

nextTick(() => {
  setTimeout(() => {
    let topicContentTd = document.getElementsByTagName('td')
    if (topicContentTd && topicContentTd.length) {
      for (let i = 0; i < topicContentTd.length; i++) {
        // 内容字号放大了，若td设置了width,也需放大
        if (topicContentTd[i].width) {
          topicContentTd[i].width = topicContentTd[i].width * 18 / 16
        }
      }
    }
  })
})

const setAnswer = (item, index) => {
  emit('setAnswer', item, index, topicIndex)
}

const rands = utilsRand

</script>

<style lang="scss" scoped>
.warp {
  display: flex;
  margin: 20rem 0 20rem 20rem;
  .left_topic {
    flex: 1;
    background: white;
    overflow: auto;
    padding: 10px;
    margin-right: 20rem;
  }
  .opertion {
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      height: 50px;
      background: #2196f3;
      display: flex;
      align-items: center;
      img {
        width: 11px;
        height: 14px;
      }
    }
  }
  .right_topic_info {
    background: #f6f6f6;
    overflow: auto;
    flex: 1;
    .card {
      padding: 10px;
      background-color: white;
      margin-top: 15px;
      &:nth-child(1) {
        margin-top: 0px;
      }
    }
  }
}
.xiao_warp {
  width: calc(100vw - 140rem);
  background: white;
  overflow: auto;
  padding: 10px;
  margin: 0 0 20rem 20rem;
}
.content {
  overflow: auto;
  font-size: 20rem;
}
.box {
  overflow-y: scroll;
}
.title {
  font-size: 20rem;
  margin-bottom: 20rem;
  font-weight: 500;
  color: #666666;
  line-height: 20px;
  position: relative;
}
.question_id {
  position: absolute;
  right: 0;
  top: 0;
}
.other_info {
  line-height: 33rem;
  font-size: 20rem;
  color: #999;
}
</style>