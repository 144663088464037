import { instance } from '@/utils/http.js'

//打印试卷
export const exportPaper = function (params) {
  return instance({
    url: '/api/v1/my/print_pdf',
    method: 'get',
    responseType: "blob",
    params
  })
}