<template>
  <div class="right_card_wrap">
    <!-- <ErrorForm ref="errorFormRef" /> -->
    <div v-for="item in topic_numer"
         :style="{'background':isActive == item ?'#3F9EFF':'#CCCCCC','margin-top':item == 1?'':'12rem'}"
         @click="toMaodian(item)"
         :key="item">
      {{item }}</div>
  </div>
</template>

<script setup>
// import ErrorForm from './error.vue'

import { ref, toRefs } from "@vue/reactivity";
import { nextTick, onMounted, onUnmounted } from "@vue/runtime-core";

let isActive = ref(1)
const propsP = defineProps({
  topic_numer: {
    type: Number,
    default: 0
  }
})
const { topic_numer } = toRefs(propsP)

// 当组件卸载的时候
onUnmounted(() => {
  document.removeEventListener('scroll', listenerFunction, true)
})

const mountScrollEvent = () => {
  nextTick(() => {
    document.addEventListener('scroll', listenerFunction, true);
  })
}

let isClick = ref(false)
const toMaodian = (index) => {
  isActive.value = index
  let anchorElement = document.getElementsByClassName('yuan')[index - 1];
  isClick.value = true
  if (anchorElement) {
    anchorElement.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      isClick.value = false
    }, 1500)
  }
}

const listenerFunction = () => {
  if (!isClick.value) {
    nextTick(() => {
      for (var index = 0; index < topic_numer.value; index++) {
        if (!document.getElementsByClassName('yuan')[index]) {
          document.removeEventListener('scroll', listenerFunction)
        }
        let row = document.getElementsByClassName('yuan')[index]
        if (row) {
          var getAttr = row.getBoundingClientRect()
          if (getAttr.top < 500 && getAttr.top > -500) {
            isActive.value = index + 1
          }
        }
      }
    })
  }
}

const jiucuo = () => {
  let form = {
    question_id: '',
    category: 0,
    comment: '',
  }
  // this.$refs.errorFormRef.form = form
  // this.$refs.errorFormRef.dialogVisible = true
}

defineExpose({
  toMaodian,
  mountScrollEvent
})

</script>

<style lang="scss" scoped>
.right_card_wrap {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  width: 80rem;
  height: calc(100vh - 120rem);
  overflow-y: scroll;
  position: fixed;
  right: 30rem;
  top: 100rem;
  margin-bottom: 20rem;
  div {
    width: 44rem;
    height: 44rem;
    border-radius: 50%;
    background: #cccccc;
    display: flex;
    justify-content: center;
    align-content: center;
    color: white;
    font-size: 24rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .opertion_wrap {
    position: fixed;
    top: 30rem;
    right: 140rem;
    background: none;
    display: flex;
    // width: 200rem;
    img {
      background: none;
      cursor: pointer;
      width: 30rem;
      height: 30rem;
      margin-left: 30rem;
    }
  }
}
</style>